import React, { PropsWithChildren, useContext } from 'react'
import { SpecialistPracticeBookingCardProps } from '../types'
import { ProfileContext } from '../../../../providers/AppData/Profile'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { AppDataContext } from '../../../../providers/AppData'
import {
  Avatar,
  Box,
  Grid,
  Rating,
  styledComponent,
  SxProps,
  Typography,
  useTheme,
  WhiteTooltip
} from '@hermes/web-components'
import PremiumBadge from '../../../Icons/PremiumBadge'
import NextAvailableSlotLabel from '../NextAvailableSlotLabel'
import { useRouter } from 'next/router'
import { TENANTS } from '../../../../constants/global'

export const RevealsContainer = styledComponent(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  width: '100%',
  marginTop: '16px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    '& > button, & > a': {
      flex: 1
    }
  }
}))

const Root = styledComponent(Grid)(({ theme }) => ({
  padding: '16px 0',
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  '&:first-of-type': {
    borderTop: `1px solid ${theme.palette.divider}`
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}))

const StyledAvatar = styledComponent(Avatar)(({ theme }) => ({
  width: 82,
  height: 82,
  marginRight: '16px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[600]}`
}))

const PremiumTooltip = ({ children }: { children: React.ReactNode }) => {
  const translate = useTranslateMessage()

  return (
    <WhiteTooltip title={translate('filter.premium.description')}>
      <Box sx={{ width: 'fit-content' }}>{children}</Box>
    </WhiteTooltip>
  )
}

const Layout = ({
  specialistPractice,
  onlineBookingAvailable,
  firstAvailableBooking,
  premium,
  showRatingSeparately,
  children
}: PropsWithChildren<SpecialistPracticeBookingCardProps>) => {
  const { locale } = useRouter()
  const { practice } = useContext(ProfileContext)
  const translate = useTranslateMessage()
  const { language } = useContext(AppDataContext)
  const theme = useTheme()

  const { contactName, image, logo, overallExperience, reviewsTotal, address } = specialistPractice
  const profileAvatarAbbreviation = contactName?.[0].toUpperCase()

  const RatingAndReviewSection = ({ containerStyles }: { containerStyles?: SxProps }) => (
    <Grid container alignItems="center" columnSpacing={1} sx={{ marginLeft: 0, ...containerStyles }}>
      <Grid container width="auto">
        <Rating size="small" sx={{ fontSize: '15px' }} value={1} max={1} readOnly />
      </Grid>
      <Grid item sx={{ paddingLeft: '4px !important' }}>
        <Typography variant="body2" fontWeight={showRatingSeparately ? '' : '500'}>
          {overallExperience} Trust Score
        </Typography>
      </Grid>
      <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>•</span>
      <Grid item sx={{ paddingLeft: '0px !important' }}>
        <Typography variant="body2" fontWeight="400" color="text.disabled">
          {reviewsTotal} {translate('reviews.reviews').toLowerCase()}
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <Root container direction="column" alignItems="center">
      {(!!specialistPractice || !!practice) && (
        <>
          <Grid container item direction="row" wrap="nowrap">
            <Grid item>
              <StyledAvatar src={image || logo}>{profileAvatarAbbreviation}</StyledAvatar>
            </Grid>
            <Grid container item direction="column" rowSpacing={1}>
              <Grid item mb="4px">
                <Typography
                  fontSize="18px"
                  sx={theme.custom.SpecialistPage.BookingCardLayoutName}
                  lineHeight="22.4px"
                  variant="h4"
                  color="text.primary"
                >
                  {contactName}
                </Typography>
              </Grid>

              {!!reviewsTotal && (
                <Grid container gap="4px" flexDirection="column">
                  {address && (
                    <Grid item>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >{`${address?.street1?.[language]}, ${address?.city?.[language]} ${address?.postcode?.[language]}`}</Typography>
                    </Grid>
                  )}
                  {premium && locale === TENANTS.WPA && (
                    <PremiumTooltip>
                      <PremiumBadge sx={{ width: '106px', height: '28px' }} />
                    </PremiumTooltip>
                  )}

                  {!showRatingSeparately && !onlineBookingAvailable ? <RatingAndReviewSection /> : null}
                </Grid>
              )}
            </Grid>
          </Grid>
          {showRatingSeparately || onlineBookingAvailable ? (
            <>
              <RatingAndReviewSection containerStyles={{ margin: '16px 0 8px 0' }} />
              {onlineBookingAvailable && (
                <NextAvailableSlotLabel withoutDateTime={!firstAvailableBooking}>
                  {firstAvailableBooking}
                </NextAvailableSlotLabel>
              )}
            </>
          ) : null}
        </>
      )}

      <RevealsContainer>{children}</RevealsContainer>
    </Root>
  )
}

export default Layout
