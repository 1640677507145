import React from 'react'
import { ContactDescriptionProps } from '../types'
import { RevealLink } from '../../../RevealLink'

const ContactDescription = ({
  //todo: fix lint
  // eslint-disable-next-line no-unused-vars
  description,
  revealName,
  skipReveal,
  ...props
}: ContactDescriptionProps) => (
  <RevealLink {...props} skipReveal={skipReveal} newWindow captchaEnabled>
    {revealName}
  </RevealLink>
)

export default ContactDescription
