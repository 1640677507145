import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import styledComponent from '../../node_modules/@mui/material/styles/styled.esm.js';
import Box from '../../node_modules/@mui/material/Box/Box.esm.js';

const Spinner = styledComponent(Box, {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'color' && prop !== 'dotSize'
})(({ size, color, dotSize }) => ({
    width: size,
    height: size,
    borderRadius: '50%',
    border: `${dotSize}px dotted ${color}`,
    animation: 'rotate 1.5s infinite linear',
    '@keyframes rotate': {
        to: { transform: 'rotate(360deg)' }
    }
}));
const DotRingSpinner = (_a) => {
    var { size = 25, dotSize = 4, color = '#E6EEF6' } = _a, props = __rest(_a, ["size", "dotSize", "color"]);
    return (React__default.createElement(Spinner, Object.assign({ size: size, color: color, dotSize: dotSize }, props)));
};

export { DotRingSpinner as default };
